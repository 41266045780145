import { kirbyBooleanTick, kirbyRemove } from "maples-kirby-icons";
import { renderToStaticMarkup } from "react-dom/server";
import { IconCell } from "helpers/gridCells";
import { emitCustomEvent } from 'react-custom-events';
import { getDisplayName } from "common-types/serviceRequests/utils/basketItemDtoUtils";

export const orderReviewColumns = [
  {
    title: "Document Type",
    field: "name",
    formatter: (cell: any, _formatterParams: any, onRendered: Function) => {
      return getDisplayName(cell.getRow().getData());
    }
  },
  {
    title: "Certification Type",
    field: "serviceRequestCertificationType",
  },
  {
    title: "Express",
    field: "isExpress",
    width: 7,
    resizable: false,
    hozAlign: 'center',
    cssPart: 'expresscell',
    formatter: (cell: any, _formatterParams: any, onRendered: Function) => {
      onRendered(() => {
        if (cell.getValue()) {
          cell.getElement().querySelector('kirby-icon').icon = kirbyBooleanTick;
          cell.getElement().querySelector('kirby-icon').title = 'Express Selected';
        }
      })
      return cell.getValue() ? renderToStaticMarkup(IconCell('Express Selected', undefined, undefined, 'var(--maples-text-main)')) : '';
    },
  },
  {
    title: "Quantity",
    field: "quantity",
    width: 8,
    hozAlign: 'right',
    headerHozAlign: 'right',
    resizable: false,
    formatter: (cell: any, _formatterParams: any, onRendered: Function) => {
      return cell.getValue() === 0 ? cell.getRow().getData().previousQuantity : cell.getValue();
    }
  },
  {
    title: '',
    field: 'buttonCell',
    cssClass: 'sticky right',
    headerSort: false,
    width: 3.4,
    hozAlign: 'center',
    resizable: false,
    formatter: (cell: any, _formatterParams: any, onRendered: Function) => {
      onRendered(() => {
        cell.getElement().querySelector('kirby-button').icon = kirbyRemove;
        cell.getElement().querySelector('kirby-button').title = 'Remove';
        cell.getElement().querySelector('kirby-button').part="removeItemButton";
        cell.getElement().querySelector('kirby-button').handleClick = () => {
          emitCustomEvent("removeRow", cell.getRow().getData())
        }
      })
      return `<kirby-button></kirby-button>`
    }
  }
];
