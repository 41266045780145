import { DocumentDto } from 'common-types/documentDto';
import { AxiosResponse } from "axios";
import { DocumentSearchResultDto } from "common-types/documentSearchResultDto";
import { addToast, removeToast, updateToast } from "components/common/Toaster";
import { TreeItem } from "maples-kirby/dist/types/types/tree";
import { browserDownloadResult } from "modules/download/fileDownload";
import { getFileName } from "modules/download/fileResult";
import { Copy } from "constants/copy/document-management/copy";
import { downloadOptions } from "../Common/downloadOptions";
import { deleteDocument, download, renameDocument, searchDocs, upload, zip } from 'api/doc-management-api';
import { ApiResponse } from 'helpers/apiCaller/ApiResponse';
import { navigateToPage } from 'helpers/history';

export async function renameDocumentItem(entityId: number, 
                                          renameFolderName:string, 
                                          documentKey: string) {

    let request = {documentId: documentKey, newName: renameFolderName};                                        
    const response = await renameDocument(entityId, request);

    return constructResponse(response);
}

function constructResponse(response: ApiResponse<any>) {
  return {
    success: response.success,
    statusCode: response.statusCode,
    error: response.errors?.join(' - ') ?? "",
    value: response.value ? response.value : undefined
  }
}

const getItemPhrase = (count: number) => {
  return count > 1 ? "items" : "item";
}

export async function deleteDocuments(entityId: number, 
                                      docs: TreeItem[], 
                                      onDocumentDeleteSuccess: Function,
                                      silent?: boolean) { 
  let failCount : number = 0;
  let successCount : number = 0;

  let successToastId : any = undefined;
  let errorToastId : any = undefined;

  async function updateToastMessage(toastId : any, message : string, messageType: string){
    if (toastId === undefined) {
      toastId = await addToast(Copy.DeleteDocumentTitle, message, messageType);
    } else {
      updateToast(toastId, Copy.DeleteDocumentTitle, message, messageType);
    }
    return toastId;
  }

  async function handleDeleteError(message?: string | undefined) {

    failCount++;

    let itemPhrase = getItemPhrase(failCount);

    updateToastMessage(errorToastId, message ?? `Error deleting ${failCount} ${itemPhrase}`, "error")
    .then((toastId) => errorToastId = toastId);
  }

  async function handleDeleteSuccess() {
    
    successCount++;
    
    let message = `Deleted ${successCount} ${getItemPhrase(successCount)}`;

    updateToastMessage(successToastId, message, "success")
    .then((toastId) => successToastId = toastId);    
  }

  for(const doc of docs){   
    try{

      deleteDocument(entityId, doc.key.toString())
      .then((result) => {
        
          switch(result.statusCode){
            case 204:{
              if (!silent) handleDeleteSuccess();
              onDocumentDeleteSuccess(doc.key.toString());
              break;
            }
            case 404: {
              handleDeleteError(Copy.ItemNotFoundMessage);
              onDocumentDeleteSuccess(doc.key.toString());
              break;
            }
            case 400: 
            case 405:{
              handleDeleteError(Copy.DeleteItemNotAllowedMessage);
              break;
            }
            default:{
              break;
            }            
          }
      });
    }
    catch(err){
      handleDeleteError();
    }      
  }      
}

export const downloadDocs = async (entityId: number, downloadItems: DocumentDto[], options: downloadOptions) => {
  let toastId = await addToast("Downloading", options.toastMessage, "spinner");

  const blobHandler = (axiosResponse: AxiosResponse<any, any>) => {
    browserDownloadResult(new Blob([axiosResponse.data]), getFileName(axiosResponse.headers));
  };

  let downloadFunction: Function;
  let downloadFunctionInput: any;

  if (options.forceZip || downloadItems.length > 1) {
    let items = downloadItems.map(a => {
      return { documentId: a.id, isFolder: a.isFolder, name: a.displayName };
    });
    downloadFunctionInput = { downloadItems: items, zipFileName: options.fileName }
    downloadFunction = zip;
  }
  else {
    downloadFunctionInput = downloadItems[0].id;
    downloadFunction = download;
  }

  downloadFunction(entityId, downloadFunctionInput, blobHandler).then((a: any) => {
    if (a.statusCode < 500 && a.statusCode >= 400) {
      removeToast(toastId);
      if (a.statusCode == 403) {
        navigateToPage('403');
      }
      return;
    }

    if (!a.success) {
      let error = a.errors ? a.errors[0] : "Unkown Error";
      updateToast(toastId, "Download failed", error, "error");
      return;
    }

    updateToast(toastId, "Download complete", options.toastCompleteMessage ?? '', "success");
  });
}

export const searchDocuments = async (entityId: number, searchTerm: string, callback: (result: DocumentSearchResultDto[] | string) => void) => {
  await searchDocs(entityId, searchTerm).then(callback);
}

const onUploadComplete = (file: File, response: ApiResponse<DocumentDto>, toastId: string) => {
      
  let title = `Upload complete`;
  let message = `Document '${response.value?.displayName}' has been uploaded.`;
  let status = 'success';

  if (!response.success) {
    title = Copy.UploadFailTitle;
    message = `Failed to create document '${file.name}'. ${response.errors ? response.errors[0] : Copy.UnknownError}`;
    status = 'error';
  }

  updateToast(toastId, title, message, status)
}

const filterValidFiles = async (files: File[])  =>  {
  let validFiles: File[] = [];

  for (let file of files) {
    if (!file.type) {
      await addToast(`Error`, `'${file.name}' is a folder and not a valid document type.`, 'error');
      continue;
    }

    validFiles.push(file);
  }

  return validFiles;
}

export const uploadDocuments = async (
  entityId: number, 
  parentFolderId: string, 
  files: File[], 
  refresh: () => Promise<void>,
  onFileOverwriteConfirmation: (files: File[], parentFolderId: string) => Promise<boolean>) => {

    const validFiles = await filterValidFiles(files);
    const continueUpload = await onFileOverwriteConfirmation(validFiles, parentFolderId);

    if (continueUpload) {
      let processedCount = 0;
      for (let i = 0; i < validFiles.length; i++) {
        const currentFile = validFiles[i];
        const toastId = await addToast(`Uploading`, `Uploading file  ${currentFile.name}...`, 'info');

        upload(entityId, { parentFolderId, file: currentFile })
          .then((response: ApiResponse<DocumentDto>) => {
            onUploadComplete(currentFile, response, toastId);
          })
          .catch(() => {
            updateToast(toastId, Copy.UploadFailTitle, `Failed to create document '${currentFile.name}'. ${Copy.UnknownError}`, 'error')
          })
          .finally(() => {
            processedCount++;
            if (processedCount === validFiles.length)
              refresh();
          });
      }
    }
}
