import {
  CurrencyCell,
  DownloadInvoiceLinkButton,
  EntityHyperLinkCell,
  IconCell,
  LinkClick,
} from "helpers/gridCells";
import { kirbyPdf } from "maples-kirby-icons";
import { renderToStaticMarkup } from "react-dom/server";
import styles from "./invoices.module.scss";
import { handleInvoiceDownload } from "./invoiceEventHandlers";
import { DateTime } from 'luxon';
declare global {
  interface Window {
      luxon: any;
  }
}
window.luxon = { DateTime };


export const invoicesGridColumns: Array<any> = [
  {
    title: "Invoice Number",
    field: "invoiceNumber",
    formatter: (cell: any, _formatterParams: any, onRendered: Function) => {
      onRendered(() => {

        if (!cell.getRow().getData().hasFile) {
          const invoiceNumberDiv = cell
            .getElement()
            .querySelector("div");

          const invoiceNumberIcon = cell
            .getElement()
            .querySelector("kirby-icon");

          invoiceNumberDiv.setAttribute("title", "The invoice is not currently available to download.");
          invoiceNumberIcon.remove();

          return;
        }

        const downloadIcons = cell.getElement().querySelector("kirby-icon");
        downloadIcons.icon = kirbyPdf;
        cell
          .getElement()
          .querySelector("div")
          .setAttribute("part", "downloadCell");
        downloadIcons.setAttribute("part", "downloadCellIcon");

        const invoiceNumberCell = cell
          .getElement()
          .querySelector(
            `#invoice-download-${cell.getRow().getData().invoiceNumber}`
          );

        invoiceNumberCell.onclick = async () => {
          await handleInvoiceDownload(
            cell.getRow().getData().entityId,
            cell.getRow().getData().invoiceNumber
          );
        };
      });
      const children = (
        <div className={styles.download} title="Download">
          {IconCell("Download", kirbyPdf, "20px", "#233D93")}
          {cell.getRow().getData().invoiceNumber}
        </div>
      );

      return renderToStaticMarkup(
        DownloadInvoiceLinkButton(
          cell.getRow().getData().invoiceNumber,
          children
        )
      );
    },
  },
  {
    title: "Entity Name",
    field: "entityName",
    headerFilter: "text",
    formatter: (cell: any, _formatterParams: any, onRendered: Function) => {
      onRendered(() => {
        cell.getElement().querySelector("a").onclick = LinkClick;
      });
      return renderToStaticMarkup(
        EntityHyperLinkCell(
          cell.getRow().getData().entityId,
          cell.getRow().getData().entityName,
          "View Entity"
        )
      );
    },
  },
  {
    title: "Client Number",
    field: "clientNumber",
    headerFilter: "text",
  },
  {
    title: "Invoice Date",
    field: "invoiceDate",
    formatterParams: {
      dataFormat: "iso",
      displayFormat: "dd-MM-yyyy",
      invalidPlaceholder: "(invalid date)",
    },
    headerFilter: "date",
    headerFilterParams: {
      maxFilterDate: DateTime.now().toFormat('yyyy-MM-dd'),
      featuresEnabled:{
        dateAfter:true,
        dateRangeMatch:true
      }
    },
    width: 12.5,
    formatter: (cell: any, _formatterParams: any, onRendered: Function) =>
      DateTime.fromISO(cell.getRow().getData().invoiceDate).toFormat('dd-MMM-yyyy')
  },
  {
    title: "Invoice Amount (USD)",
    field: "amount",
    formatter: (cell: any, _formatterParams: any, onRendered: Function) =>
    CurrencyCell(cell),
    hozAlign: "right",
    headerHozAlign: "right",
  },
  {
    title: "Settled (USD)",
    field: "settled",
    formatter: (cell: any, _formatterParams: any, onRendered: Function) =>
    CurrencyCell(cell),
    hozAlign: "right",
    headerHozAlign: "right",
  },
  {
    title: "Outstanding (USD)",
    field: "outstanding",
    formatter: (cell: any, _formatterParams: any, onRendered: Function) =>
    CurrencyCell(cell),
    hozAlign: "right",
    headerHozAlign: "right",
  },
  {
    title: "Status",
    field: "status",
    headerFilter: "select",
    headerFilterParams: {
      options: [
        {
          value: "Outstanding",
          label: "Outstanding",
        },
        {
          value: "Settled",
          label: "Settled",
        },
      ],
    },
  },
];
